import Header from 'components/core/pageHeader/Header'
import Form from './form/Form'
import Inquiry from './inquiry/Inquiry'

const Bill = (props) => {
  return (
    <div className="page-gray">
      <Header title=" پرداخت قبض" />
      <Form />
      <Inquiry />
    </div>
  )
}

export default Bill
