
import  classes from './DynamicPassBtn.module.scss';

const PouyaPass=(props)=>{
    return (
        <div className={`${classes.dynamicPassBtn} ${props.className}`}>
            <button type="button">
                دریافت رمز پویا
            </button>
        </div>
    )
}

export default PouyaPass;