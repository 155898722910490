import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import payment from 'store/slices/payment'

import classes from './inqueryData.module.scss'

const InquiryData = (props) => {
  const PaymentData = useSelector((state) => state.payment)
  const oprType = useSelector((state) => state.charge.type)

  return (
    <Fragment>
      <div className={classes.list}>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/${oprType}`} alt="" />
          <span className={classes.title}>{PaymentData.title}</span>
        </div>

        <span className={classes.amount}>{`${PaymentData.amount}`}</span>
      </div>
    </Fragment>
  )
}

export default InquiryData
