import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'

import Payment from "components/core/payment/Payment";
import Header from "components/core/pageHeader/Header";
import MyCards from "components/core/myCards/MyCards";
import { fetchCards } from 'store/actions/card';

const BillPayment = (props) => {
  const data = useSelector((state) => state.form.bill);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCards('source'));
  }, []);
  
  return (
    <div className="page-gray">
      <Header title=" پرداخت " />
      <MyCards />
      <Payment url={"billPayment"} receiptUrl={"billReceipt"} data={data} />
    </div>
  );
};
export default BillPayment;
