import { createSlice } from "@reduxjs/toolkit";

const initState = {
  loginStep: 1,
  mobileNumber: "",
  activeCode: "",
  identifierCode: "",
  // recommenderCode='',
  token: localStorage["token"],
};

const auth = createSlice({
  name: "authentication",
  initialState: initState,
  reducers: {
    setMobileNumber(state, action) {
      state.mobileNumber = action.payload;
    },
    setIdentifierCode(state, action) {
      state.identifierCode = action.payload;
    },
    goLoginStep1(state) {
      state.loginStep = 1;
    },
    goLoginStep2(state) {
      state.loginStep = 2;
    },
    setActiveCode(state, action) {
      state.activeCode = action.payload;
    },
    setRecommenderCode(state, action) {
      state.recommenderCode = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
      localStorage["token"] = action.payload;
    },
    logout(state, action) {
      state.token = "";
      localStorage["token"] = "";
      state.loginStep = 1;
    },
  },
});

export const AuthActions = auth.actions;

export default auth;
