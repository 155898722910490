import { useDispatch,useSelector } from 'react-redux';

import classes from './CrudBtn.module.scss';
import { UiActions } from 'store/slices/ui';
import { deleteCard } from 'store/actions/card';
import { Fragment } from 'react';

const CrudBtn=(props)=>{

   const dispatch=useDispatch();
   const cards=useSelector(state=>state.cards);
   const uiState=useSelector(state=>state.ui);

   const addShowHandler=()=>{
      dispatch(UiActions.addCardShowChange());
      dispatch(UiActions.setAddCardState("addCard"));

   }
   const editShowHandler=()=>{
      dispatch(UiActions.addCardShowChange());
      dispatch(UiActions.setAddCardState("updateCard"));
   }

   const deleteHandler=()=>{
      dispatch(deleteCard(uiState,cards.selected))
   }

    return (
            <div className={classes.btnContainer}>
               <button className={classes.add} onClick={addShowHandler}>
                  <span>+</span>
               </button>
               {cards.data.length > 0 && <Fragment>
                  <button className={classes.edit} onClick={editShowHandler}>
                     <span>&#9998;</span>
                  </button>
                  <button className={classes.delete} onClick={deleteHandler}>
                     <span className="icon-trash"></span>
                  </button>
               </Fragment>}


            </div>
            )
}

export default CrudBtn;