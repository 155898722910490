import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'

import Payment from 'components/core/payment/Payment'
import Header from 'components/core/pageHeader/Header'
import MyCards from 'components/core/myCards/MyCards'
import { fetchCards } from 'store/actions/card'
import { PaymentAction } from '../../../../../store/slices/payment'

const ChargePayment = () => {
  const amount = useSelector((state) => state.charge.selectedAmount).replace(
    ',',
    '',
  )
  const mobile = useSelector((state) => state.form.charge.mobileNumber)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCards('source'))
  }, [])

  return (
    <div className="page-gray">
      <Header title=" پرداخت" url="buyCharge" />
      <MyCards />
      <Payment
        url={'chargeTopup'}
        data={{
          mobile_number: mobile,
          amount: amount,
        }}
        receiptUrl={'chargeReceipt'}
      />
    </div>
  )
}

export default ChargePayment
