import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import Input from 'components/core/input/Input'
import OptBtn from 'components/core/buttons/optBtn/OptBtn'
import classes from './Form.module.scss'
import { formActions } from 'store/slices/form'
import { addCard } from 'store/actions/card'

const Form = () => {
  const dispatch = useDispatch()
  const cardForm = useSelector((state) => state.form.myCards)
  const uiState = useSelector((state) => state.ui)
  const selectedCard = useSelector((state) => state.cards.selected)

  useEffect(() => {
    if (uiState.addCardState === 'updateCard') {
      dispatch(formActions.setMyCardsTotal(selectedCard))
    } else {
      dispatch(formActions.resetMyCards())
    }
  }, [dispatch, selectedCard, uiState.addCardState])

  const submitForm = (event) => {
    event.preventDefault()
    dispatch(addCard(uiState, cardForm))
  }
  const setFormValue = (name, value) => {
    let content = value;


    dispatch(formActions.setMyCardsValue({ name: name, value: content }))
  }

  return (
    <div className="page-gray">
      <form className={classes.form} onSubmit={submitForm}>
        <Input
          label="عنوان کارت :"
          input={{
            type: 'text',
            value: cardForm.title,
            onChange: (event) => setFormValue('title', event.target.value),
          }}
        />

        <Input
          label="شماره کارت :"
          mask="9999 9999 9999 9999"
          input={{
            type: 'tel',
            value: cardForm.cardNumber,
            onChange: (event) => setFormValue('cardNumber', event.target.value.replace(/\s/g, '')),
          }}
        />
    

        {uiState.manageCardTabSelected === 'source' && (
          <Input
            className="col-12"
            label="تاریخ انقضا :"
            mask="99/99"
            input={{
              type: 'tel',
              value: cardForm.expireDate,
              onChange: (event) =>
                setFormValue('expireDate', event.target.value.replace(/\s/g, '')),
            }}
          />
        )}
        <OptBtn type="submit" text="ثبت کارت" />
      </form>
    </div>
  )
}

export default Form
