import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Step1 from './step1/Step1'
import Step2 from './step2/Step2'

const Form = () => {
  const stepShow = useSelector((state) => state.auth.loginStep)
  const [viewState, setViewState] = useState(null)

  useEffect(() => {
    if (stepShow === 1) {
      setViewState(<Step1 />)
    } else if (stepShow === 2) {
      setViewState(<Step2 />)
    }
  }, [stepShow])

  return <Fragment>{viewState}</Fragment>
}

export default Form
