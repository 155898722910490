import { configureStore } from '@reduxjs/toolkit'

import auth from './slices/auth'
import ui from './slices/ui'
import modal from './slices/modal'
import form from './slices/form'
import card from './slices/card'
import charge from './slices/charge'
import payment from './slices/payment'
import inquiry from './slices/inquiry'

const store = configureStore({
  reducer: {
    auth: auth.reducer,
    ui: ui.reducer,
    modal: modal.reducer,
    form: form.reducer,
    cards: card.reducer,
    charge: charge.reducer,
    payment: payment.reducer,
    inquiry: inquiry.reducer,
  },
})

export default store
