import { createSlice } from '@reduxjs/toolkit'

const initState = {
  overlay: false,
  message: {
    type: '',
    title: '',
    text: '',
  },
}

const modal = createSlice({
  name: 'modal',
  initialState: initState,
  reducers: {
    message(state, action) {
      state.overlay = action.payload.text ? true : false
      state.message = {
        text: action.payload.text,
        type: action.payload.type,
      }
    },
  },
})

export const modalActions = modal.actions

export default modal
