import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import Header from 'components/core/pageHeader/Header'
import MyCards from 'components/core/myCards/MyCards'
import CartToCartForm from 'components/forms/cartToCartForm/CartToCartForm'
import { fetchCards } from 'store/actions/card'
import Inquery from './Inquery/inquery'

const CardToCard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCards('source'))
  }, [])

  return (
    <div className="page-gray">
      <Header title="کارت به کارت" />
      <MyCards />
      <CartToCartForm />
      <Inquery />
    </div>
  )
}

export default CardToCard
