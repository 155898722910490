import classes from './Info.module.scss';

const Info=(props)=>{
    return (
            <div className={classes.cardInfo}>
               <span className={classes.title}>{props.title}</span>
               <span className={classes.value}>{props.value}</span>
            </div>
            )
}

export default Info;