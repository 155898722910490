import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { UiActions } from "store/slices/ui";

const NavItem = (props) => {
  const dispatch = useDispatch();

  const closeNavHandler = () => {
    dispatch(UiActions.navShowChange());
  };

  return (
    <Fragment>
      <li onClick={props.onClick ? props.onClick : closeNavHandler}>
        <Link to={props.link}>
          <i className={props.classname}></i>
          <span>{props.text}</span>
        </Link>
      </li>
    </Fragment>
  );
};

export default NavItem;
