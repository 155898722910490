import { useSelector } from "react-redux";


const useAuth=()=>{

    const token=useSelector(state=>state.auth.token);
    const isAuth=token ? true : false;
    return {
        isAuth
    }
};

export default useAuth