import { Link } from 'react-router-dom'

import classes from './Header.module.scss'

const Header = (props) => {
  return (
    <header className={classes.pageHeader}>
      <Link to={props.url ? props.url : 'dashboard'}>
        <i className="icon-rightarrow"></i>
      </Link>
      <span>{props.title}</span>
    </header>
  )
}

export default Header
