import { useSelector, useDispatch } from 'react-redux'

import classes from './Form.module.scss'
import FormContainer from 'components/core/formContainer/FormContainer'
import useHttp from 'hooks/useHttp'
import { modalActions } from 'store/slices/modal'
import Scan from 'components/core/scan/Scan.js'
import { UiActions } from 'store/slices/ui'
import Input from 'components/core/input/Input'
import OptBtn from 'components/core/buttons/optBtn/OptBtn'
import { formActions } from 'store/slices/form'
import { PaymentAction } from 'store/slices/payment'
import { InquiryAction } from 'store/slices/inquiry'
import { useEffect } from 'react'

const Form = (props) => {
  const dispatch = useDispatch()
  const billForm = useSelector((state) => state.form.bill)
  const showScan = useSelector((state) => state.ui.scan)
  const setFormValue = (name, value) => {
    dispatch(formActions.setBillValue({ name: name, value: value }))
  }
  const inquiryShowHandler = () => {
    dispatch(UiActions.inquiryShowChange())
  }
  useEffect(() => {
    dispatch(formActions.resetBillValue())
  }, [])

  const inquiryButtonClass =
    billForm.billId && billForm.payId
      ? `${classes.enable}`
      : `${classes.disable}`
  const httpResponse = (data) => {
    dispatch(
      PaymentAction.setPaymentData({
        title: ` قبض ${data.title}`,
        amount: `مبلغ:  ${data.amount} `,
        type: `${data.type}`,
        image: `bill/${data.type}.png`,
      }),
    )

    dispatch(
      InquiryAction.setInquiryBillValue({
        paid: data.paid,
      }),
    )

    inquiryShowHandler()
  }

  const { isLoading, sendRequest } = useHttp(
    {
      url: 'service/pelato/billInquiry',
      method: 'POST',
      body: {
        ...billForm,
      },
    },
    httpResponse,
  )

  const submitFormHandler = (event) => {
    event.preventDefault()
    sendRequest()
  }
  const scanShowHandler = () => {
    dispatch(UiActions.scanShowChange())
  }
  return (
    <div className="page-gray mt-5">
      <FormContainer>
        <fieldset className={classes.fieldset} disabled={isLoading}>
          <form onSubmit={submitFormHandler}>
            <Input
              label="شناسه قبض :"
              input={{
                type: 'tel',
                value: billForm.billId,
                onChange: (event) => setFormValue('billId', event.target.value),
              }}
            />
            <Input
              label="شناسه پرداخت :"
              input={{
                type: 'tel',
                value: billForm.payId,
                onChange: (event) => setFormValue('payId', event.target.value),
              }}
            />
            {/* {showScan ? <Scan className={classes.scan} /> : null} */}
            <img
              className={classes.img}
              src={`${process.env.PUBLIC_URL}/images/scan.png`}
              alt="scan component"
              onClick={scanShowHandler}
            />
            <OptBtn
              type="submit"
              text="استعلام"
              isloading={isLoading}
              className={inquiryButtonClass}
              disable={!billForm.billId || !billForm.payId}
            />
          </form>
        </fieldset>
      </FormContainer>
    </div>
  )
}

export default Form
