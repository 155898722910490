import { createSlice } from "@reduxjs/toolkit";

const initState = {
  navShow: false,
  addCard: false,
  scan: false,
  inquiry: false,
  recommenderCode: false,
  addCardState: "addCard",
  manageCardTabSelected: "source",
};

const ui = createSlice({
  name: "authentication",
  initialState: initState,
  reducers: {
    navShowChange(state) {
      state.navShow = !state.navShow;
    },
      scanShowChange(state) {
      state.scan = !state.scan;
    },
    recommenderCodeChange(state) {
      state.recommenderCode = !state.recommenderCode;
    },
    closeNav(state) {
      state.navShow = false;
    },
    addCardShowChange(state) {
      state.addCard = !state.addCard;
    },
    setAddCardState(state, action) {
      state.addCardState = action.payload;
    },
    setManageCardTabSelected(state, action) {
      state.manageCardTabSelected = action.payload;
    },
    inquiryShowChange(state) {
      state.inquiry = !state.inquiry;
    },
  },
});

export const UiActions = ui.actions;

export default ui;
