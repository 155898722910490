import { CardActions } from 'store/slices/card'
import { formActions } from 'store/slices/form'
import { modalActions } from 'store/slices/modal'
import { UiActions } from 'store/slices/ui'

export const fetchCards = (type) => {
  return async (dispatch) => {
    const response = await fetch(
      'service/mobchannel/card/listCard?cardType=' + type,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: localStorage['token'],
        },
      },
    )
    const data = await response.json()
    if (response.ok) {
      dispatch(CardActions.setCards(data.cardList))
    } else {
      dispatch(
        modalActions.message({
          type: 'error',
          text: data.message || 'خطا در عملیات',
        }),
      )
    }
  }
}

export const addCard = (uiState, form) => {
  return async (dispatch) => {
    const response = await fetch(
      '/service/mobchannel/card/' + uiState.addCardState,
      {
        method: uiState.addCardState === 'addCard' ? 'POST' : 'PUT',
        body: JSON.stringify({
          ...form,
          cardType: uiState.manageCardTabSelected,
        }),
        headers: {
          'Content-Type': 'application/json',
          token: localStorage['token'],
        },
      },
    )
    const data = await response.json()
    if (response.ok) {
      dispatch(UiActions.addCardShowChange())
      dispatch(modalActions.message({ text: data.message, type: 'success' }))
      dispatch(formActions.resetMyCards())
      dispatch(UiActions.setAddCardState('addCard'))
      dispatch(fetchCards(uiState.manageCardTabSelected))
    } else {
      dispatch(
        modalActions.message({
          type: 'error',
          text: data.message || 'خطا در عملیات',
        }),
      )
    }
  }
}

export const deleteCard = (uiState, selectedCard) => {
  return async (dispatch) => {
    const response = await fetch('/service/mobchannel/card/removeCard', {
      method: 'DELETE',
      body: JSON.stringify({ cardId: selectedCard.cardId }),
      headers: {
        'Content-Type': 'application/json',
        token: localStorage['token'],
      },
    })
    const data = await response.json()
    if (response.ok) {
      dispatch(modalActions.message({ text: data.message, type: 'success' }))
      dispatch(fetchCards(uiState.manageCardTabSelected))
    } else {
      dispatch(
        modalActions.message({
          type: 'error',
          text: data.message || 'خطا در عملیات',
        }),
      )
    }
  }
}
