import { Fragment } from "react";
import { useHistory } from "react-router-dom";

import classes from "./Inquiry.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { UiActions } from "store/slices/ui";
import OptBtn from "components/core/buttons/optBtn/OptBtn";
import InquiryData from "./inquiryData/InquiryData";

const Inquiry = (props) => {
  const dispatch = useDispatch();
  const uiState = useSelector((state) => state.ui);
  const paid = useSelector((state) => state.inquiry.inquiryBillData.paid);

  const history = useHistory();

  const changeShowHandler = () => {
    dispatch(UiActions.inquiryShowChange());
  };

  const paymentHandler = () => {
    history.push("/billPayment");
    dispatch(UiActions.inquiryShowChange());
  };

  return (
      <Fragment>
        <div
            className={`${classes.inquiry} ${
                uiState.inquiry ? classes.activeInquiry : ""
            }`}
        >
          <h4>مشخصات قبض</h4>
          <span className={classes.closeIcon} onClick={changeShowHandler}>
          &#10006;
        </span>

          <div className={classes.InqueryListData}>
            <InquiryData />
            <OptBtn
                type="submit"
                text="تایید"
                onClick={paid ? changeShowHandler : paymentHandler }
            />
          </div>
        </div>

        <div
            className={`${classes.shadow} ${
                uiState.inquiry ? classes.activeShadow : ""
            }`}
            onClick={changeShowHandler}
        ></div>
      </Fragment>
  );
};

export default Inquiry;
