import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import classes from './inqueryData.module.scss'

const InquiryData = (props) => {
  const inquiry = useSelector((state) => state.inquiry.cardToCard)
  const amount = useSelector((state) => state.form.cardToCard.amount).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ',',
  )
  const destNum = useSelector((state) => state.form.cardToCard.destinationPan)
  const destNumber = destNum.replace(
    /(\d{4})(\d{4})(\d{4})(\d{4})/,
    '$4-$3-$2-$1',
  )

  return (
    <Fragment>
      <div>
        <span className={classes.icon}>
          <i className="icon-mobile"></i>
        </span>
        <span className={classes.title}>{`کارت مقصد: ${destNumber}`}</span>
      </div>

      <div>
        <i className="icon-mobile"></i>

        <span className={classes.nameT}>{`صاحب کارت:`}</span>
        <span
          className={classes.name}
        >{`${inquiry.firstName} ${inquiry.lastName}`}</span>
      </div>

      <div>
        <span className={classes.icon}>
          <i className="icon-mobile"></i>
        </span>
        <span className={classes.amountt}>{`مبلغ:`}</span>{' '}
        <span className={classes.amount}>{`${amount} ریال`}</span>
      </div>
    </Fragment>
  )
}

export default InquiryData
