import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import useHttp from 'hooks/useHttp'
import FormContainer from 'components/core/formContainer/FormContainer'
import Input from 'components/core/input/Input'
import DynamicPassBtn from 'components/core/DynamicPassBtn/DynamicPassBtn'
import OptBtn from 'components/core/buttons/optBtn/OptBtn'
import { modalActions } from 'store/slices/modal'
import { formActions } from 'store/slices/form'
import { UiActions } from 'store/slices/ui'
import { InquiryAction } from 'store/slices/inquiry'
import classes from './CartToCartForm.module.scss'

const CartToCartForm = () => {
  const dispatch = useDispatch()
  const cardToCardForm = useSelector((state) => state.form.cardToCard)
  const selectedCard = useSelector((state) => state.cards.selected)
  const sateUi = useSelector((state) => state.ui.inquiry)

  useEffect(() => {
    dispatch(formActions.resetCardToCardValue())
    setFormValue('pan', selectedCard.cardNumber)
  }, [selectedCard])

  const setFormValue = (name, value) => {
    // let content = value.replaceAll(' ', '').replaceAll('/', '')
    let content=value.replace(/\s/g, '');

    dispatch(formActions.setCardToCardValue({ name: name, value: content }))
  }

  const httpResponse = (data) => {
    if (!data.errorMessage) {
      dispatch(UiActions.inquiryShowChange())
      dispatch(InquiryAction.setInquiryCardToCardValue(data))
    } else if (data.errorMessage) {
      let element = <span>{data.errorMessage}</span>
      dispatch(modalActions.message({ type: 'error', text: element }))
    }
  }
  const { isLoading, sendRequest } = useHttp(
    {
      url: 'service/pelato/cardHolder',
      method: 'POST',
      body: {
        ...cardToCardForm,
      },
    },
    httpResponse,
  )
  const submitForm = (event) => {
    event.preventDefault()
    sendRequest()
  }

  return (
    <div className="page-gray">
      <FormContainer>
        <fieldset className={classes.fieldset} disabled={isLoading}>
          <form onSubmit={submitForm}>
            <Input
              label="شماره کارت مبدا :"
              mask="9999 9999 9999 9999"
              input={{
                type: 'tel',

                value: cardToCardForm.pan,
                onChange: (event) => setFormValue('pan', event.target.value),
              }}
            />

            <Input
              label="شماره کارت مقصد :"
              mask="9999 9999 9999 9999"
              input={{
                type: 'tel',

                value: cardToCardForm.destinationPan,
                onChange: (event) =>
                  setFormValue('destinationPan', event.target.value),
              }}
            />

            <Input
              label="مبلغ:"
              input={{
                type: 'tel',
                value: cardToCardForm.amount,
                onChange: (event) => setFormValue('amount', event.target.value),
              }}
            />

            <div className="row">
              <Input
                className="col-6 pl-10"
                label="cvv2:"
                input={{
                  type: 'password',
                  value: cardToCardForm.cvv2,
                  onChange: (event) => setFormValue('cvv2', event.target.value),
                }}
              />
              <Input
                className="col-6"
                label="تاریخ انقضا :"
                mask="99/99"
                input={{
                  type: 'tel',
                  value: cardToCardForm.expireDate,

                  onChange: (event) =>
                    setFormValue('expireDate', event.target.value),
                }}
              />
            </div>

            <div className="row">
              <Input
                className="col-6 pl-10"
                label="رمز دوم :"
                input={{
                  type: 'password',
                  value: cardToCardForm.pin,
                  onChange: (event) => setFormValue('pin', event.target.value),
                }}
              />
              <DynamicPassBtn className="col-6" />
            </div>
            <OptBtn text="تایید" type="submit" isloading={isLoading} />
          </form>
        </fieldset>
      </FormContainer>
    </div>
  )
}

export default CartToCartForm
