import { Fragment } from "react";
import classes from "./OptBtn.module.scss";
import Loader from "./../../loader/Loader";

const OptBtn = (props) => {
  return (
    <Fragment>
      <button
        type={props.type ? props.type : "button"}
        className={`${classes.optBtn} ${props.className} `}
        onClick={props.onClick}
        disabled={props.disable}
      >
        {props.isloading ? <Loader /> : props.text}
      </button>
    </Fragment>
  );
};

export default OptBtn;
