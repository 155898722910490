import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import Receipt from 'components/core/receipt/Receipt'
import OptBtn from 'components/core/buttons/optBtn/OptBtn'
import FormContainer from 'components/core/formContainer/FormContainer'
import ChargeReceiptData from './receiptData/chargeReceiptData'
import { useHistory } from 'react-router'
import { PaymentAction } from '../../../../../store/slices/payment'

const ChargeReceipt = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const amount = useSelector((state) => state.payment.paymentdata.amount)

  return (
    <FormContainer>
      <div>
        <Receipt amount={amount} title={`خرید شارژ با موفقیت انجام شد`} />
        <ChargeReceiptData />
        <OptBtn
          type="submit"
          text="بازگشت"
          onClick={() => {
            history.push('/dashboard')
          }}
        />
      </div>
    </FormContainer>
  )
}

export default ChargeReceipt
