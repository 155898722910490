import { Fragment } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import classes from './ChargeAmounts.module.scss'
import { ChargeAmountAction } from 'store/slices/charge'
import { PaymentAction } from 'store/slices/payment'

const ChargeAmounts = () => {
  const amountsList = [
    {
      id: '1',
      amount: '10,000',
    },
    {
      id: '2',
      amount: '20,000',
    },
    {
      id: '3',
      amount: '50,000',
    },
    {
      id: '4',
      amount: '100,000',
    },

    {
      id: '5',
      amount: '200,000',
    },

    {
      id: '6',
      amount: '300,000',
    },
  ]

  const [amountSelected, setAmountSelected] = useState(0)

  const dispatch = useDispatch()

  const setAmount = (value, index) => {
    dispatch(ChargeAmountAction.setSelectedValue(value))
    setAmountSelected(index)
  }

  return (
    <Fragment>
      <div className={classes.itemsBox}>
        {amountsList.map((item, index) => {
          return (
            <div className={classes.items}>
              <div
                className={`${classes.item} ${
                  amountSelected === index ? classes.active : ''
                }`}
                onClick={() => setAmount(item.amount, index)}
              >
                <span className={classes.amount}>{item.amount}</span>
                <span className={classes.currency}>ریالی</span>
              </div>
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}
export default ChargeAmounts
