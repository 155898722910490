import { Fragment } from "react";
import { useSelector } from "react-redux";
import OptBtn from "../buttons/optBtn/OptBtn";
import FormContainer from "../formContainer/FormContainer";
import classes from "./Receipt.module.scss";
//import jalaali from 'jalaali-js'

const Receipt = (props) => {
  const amount = String(props.amount)
    ? String(props.amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "";
  const today = new Date(Date.now());
  const persionDate = today.toLocaleDateString("fa-IR");
  var time = today.getHours() + ":" + today.getMinutes();
  return (
    <Fragment>
      <div className={classes.title}>
        <span>تراکنش موفق</span>
      </div>

      <div className={classes.header}>
        <span className={classes.center}>{props.title}</span>
      </div>
      <div className={classes.header}>
        <span>تاریخ:</span>
        <span>{`${time} | ${persionDate}`}</span>
      </div>
      <div className={classes.header}>
        <span>مبلغ:</span>
        <span>{`${amount}  ریال `}</span>
      </div>
      <hr />
    </Fragment>
  );
};

export default Receipt;
