import { createSlice } from '@reduxjs/toolkit'

const initState = {
  data: [],
  selected: {
    cardNumber: '',
    title: '',
    expire: '',
  },
}

const card = createSlice({
  name: 'card',
  initialState: initState,
  reducers: {
    setCards(state, action) {
      if (action.payload) {
        let data = action.payload || []
        state.data = getBanks(transformCards(data))
        state.selected = action.payload[0]
      } else {
        state.selected = {
          cardNumber: '',
          title: '',
          expire: '',
        }
        state.data = []
      }
    },
    goToLeft(state, action) {
      if (action.payload + 1 === state.data.length) {
        state.data[action.payload].right = '50%'
      } else {
        state.data[action.payload].right = '200%'
        state.data[action.payload + 1].right = '50%'
        state.selected = state.data[action.payload + 1]
      }
    },
    goToRight(state, action) {
      if (action.payload === 0) {
        state.data[action.payload].right = '50%'
      } else {
        state.data[action.payload].right = '-100%'
        state.data[action.payload - 1].right = '50%'
        state.selected = state.data[action.payload - 1]
      }
    },
    moveToRight(state, action) {
      state.data[
        action.payload.index
      ].right = `calc(50% - ${action.payload.x}px)`
      // state.data[action.payload-1].right='50%';
      // state.selected=state.data[action.payload-1];
    },
    moveToLeft(state, action) {
      state.data[
        action.payload.index
      ].right = `calc(50% + ${action.payload.x}px)`
      // state.data[action.payload-1].right='50%';
      // state.selected=state.data[action.payload-1];
    },
  },
})

const transformCards = (cards) => {
  let transformedCards = cards.map((item, index) => {
    if (index !== 0) {
      item.right = '-100%'
    } else {
      item.right = '50%'
    }
    return item
  })
  return transformedCards
}

export const CardActions = card.actions

export default card

const getBanks = (cards) => {
  if (cards) {
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].cardNumber.includes('603799')) {
        cards[i].bank = 'melli.png'
      } else if (cards[i].cardNumber.includes('589210')) {
        cards[i].bank = 'sepah.png'
      } else if (cards[i].cardNumber.includes('627648')) {
        cards[i].bank = 'toseesaderat.png'
      } else if (cards[i].cardNumber.includes('627961')) {
        cards[i].bank = 'sanatomadan.png'
      } else if (cards[i].cardNumber.includes('603770')) {
        cards[i].bank = 'keshavarzi.png'
      } else if (cards[i].cardNumber.includes('628023')) {
        cards[i].bank = 'maskan.png'
      } else if (cards[i].cardNumber.includes('627760')) {
        cards[i].bank = 'postbank.png'
      } else if (cards[i].cardNumber.includes('502908')) {
        cards[i].bank = 'tosee.png'
      } else if (cards[i].cardNumber.includes('627412')) {
        cards[i].bank = 'egtesadnovin.png'
      } else if (cards[i].cardNumber.includes('622106')) {
        cards[i].bank = 'parsian.png'
      } else if (cards[i].cardNumber.includes('502229')) {
        cards[i].bank = 'pasargad.png'
      } else if (cards[i].cardNumber.includes('627488')) {
        cards[i].bank = 'karafarin.png'
      } else if (cards[i].cardNumber.includes('621986')) {
        cards[i].bank = 'saman.png'
      } else if (cards[i].cardNumber.includes('639346')) {
        cards[i].bank = 'sina.png'
      } else if (cards[i].cardNumber.includes('639607')) {
        cards[i].bank = 'sarmaye.png'
      } else if (cards[i].cardNumber.includes('502806')) {
        cards[i].bank = 'shahr.png'
      } else if (cards[i].cardNumber.includes('502938')) {
        cards[i].bank = 'deybank.png'
      } else if (cards[i].cardNumber.includes('603769')) {
        cards[i].bank = 'saderat.png'
      } else if (cards[i].cardNumber.includes('610433')) {
        cards[i].bank = 'mellat.png'
      } else if (cards[i].cardNumber.includes('585983')) {
        cards[i].bank = 'tejarat.png'
      } else if (cards[i].cardNumber.includes('589463')) {
        cards[i].bank = 'refah.png'
      } else if (cards[i].cardNumber.includes('627381')) {
        cards[i].bank = 'bankk.png'
      } else if (cards[i].cardNumber.includes('636214')) {
        cards[i].bank = 'ayande.png'
      } else if (cards[i].cardNumber.includes('505416')) {
        cards[i].bank = 'gardeshgari.png'
      } else if (cards[i].cardNumber.includes('639599')) {
        cards[i].bank = 'gavamin.png'
      } else if (cards[i].cardNumber.includes('636949')) {
        cards[i].bank = 'hekmat.png'
      } else if (cards[i].cardNumber.includes('505785')) {
        cards[i].bank = 'iranzamin.png'
      } else {
        cards[i].bank = 'default.png'
      }
    }

    return cards
  }
}
