import { Fragment } from "react";
import { useSelector } from "react-redux";

import classes from "./InquiryData.module.scss";

const InquiryData = (props) => {
  const title = useSelector((state) => state.payment.title);
  const amount = useSelector((state) => state.payment.amount);
  const image = useSelector((state) => state.payment.image);
  const paid = useSelector((state) => state.inquiry.inquiryBillData.paid);
  const amountfinal = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return (
      <Fragment>
        <div className={classes.list}>
          <div className={classes.header}>
            <img
                src={`${process.env.PUBLIC_URL}/images/${image}`}
                alt={title}
            />

            <span className={classes.title}>{title}</span>
            <span className={classes.paid}>{paid ? '✔ قبض پرداخت شده' : ''}</span>
          </div>
            <span className={classes.amount} >{amountfinal} ریال</span>
        
        </div>
      </Fragment>
  );
};

export default InquiryData;
