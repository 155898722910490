import { createSlice } from '@reduxjs/toolkit'

const initState = {
  inquiryBillData: {
    paid: false,
  },

  cardToCard: {
    firstName: '',
    lastName: '',
  },
}

const inquiry = createSlice({
  name: 'inquiry',
  initialState: initState,
  reducers: {
    setInquiryBillValue(state, action) {
      state.inquiryBillData = action.payload
    },

    setInquiryCardToCardValue(state, action) {
      state.cardToCard.firstName = action.payload.firstName
      state.cardToCard.lastName = action.payload.lastName
    },
  },
})

export const InquiryAction = inquiry.actions
export default inquiry
