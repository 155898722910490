import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import FormContainer from 'components/core/formContainer/FormContainer'
import classes from './ReceiptData.module.scss'
import Receipt from 'components/core/receipt/Receipt'
import Inquery from '../../Inquery/inquery'

const ReceiptData = () => {
  const cardTransferData = useSelector((state) => state.form.cardToCard)
  const inquiry = useSelector((state) => state.inquiry.cardToCard)
  const payment = useSelector((state) => state.payment.cardTransferData)
  const destinationPan = cardTransferData.destinationPan.replace(
    /(\d{4})(\d{4})(\d{4})(\d{4})/,
    '$1-$2-$3-$4',
  )
  const pan = cardTransferData.pan.replace(
    /(\d{4})(\d{4})(\d{4})(\d{4})/,
    '$1-$2-$3-$4',
  )

  return (
    <div className={classes.boxshadow}>
      <div className={classes.header}>
        <span>کارت مبدا:</span>
        <span>{pan}</span>
      </div>
      <div className={classes.header}>
        <span>کارت مقصد:</span>
        <span>{destinationPan}</span>
      </div>
      <div className={classes.header}>
        <span>صاحب کارت:</span>
        <span>{`${inquiry.firstName} ${inquiry.lastName}`}</span>
      </div>
      <div className={classes.header}>
        <span>شماره پیگیری:</span>
        <span> {payment.rrn}</span>
      </div>
    </div>
  )
}
export default ReceiptData
