import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import useHttp from "hooks/useHttp";
import classes from "./Step1.module.scss";
import { AuthActions } from "store/slices/auth";
import { UiActions } from "store/slices/ui";
import Input from "components/core/input/Input";
import OptBtn from 'components/core/buttons/optBtn/OptBtn'

const Step1 = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const recommenderCode = useSelector((state) => state.ui.recommenderCode);

  const loginResponse = (data) => {
    dispatch(AuthActions.goLoginStep2());
  };

  const { isLoading, sendRequest } = useHttp(
    {
      url: "/service/mobchannel/customer/getMobileNo",
      method: "POST",
      body: { mobileNumber: auth.mobileNumber },
    },
    loginResponse
  );

  const login = (event) => {
    event.preventDefault();
    sendRequest();
  };

  const mobileStateHandler = (event) => {
    dispatch(AuthActions.setMobileNumber(event.target.value));
    

  };
  const identifierCodeHandler = (event) => {
    dispatch(AuthActions.setIdentifierCode(event.target.value));
    
  };
  const showRecommenderCode = (event) => {
    event.preventDefault();
    dispatch(UiActions.recommenderCodeChange());
  };

  return (
    <form className={classes.form}>
      <h6>لطفا شماره تلفن خود را وارد کنید : </h6>
     
      <div className={classes.formGroup}>
        <input
          type="tel"
          value={auth.mobileNumber}
          onChange={mobileStateHandler}
          placeholder="09 - - - - - - - - -"
        />
        <i className="icon-mobile"></i>
      </div>

      <div>
        <a
          className={` ${classes.recommenderlable} `}
          onClick={showRecommenderCode}
          href="/"
        >
          کد معرف دارم
        </a>
       
        <div>
          {recommenderCode && (
            <Input
              type="text"
            
              input={{
                className:`${classes.recommenderinput}`,
                placeholder: "کد معرف را وارد کنید",
                value:auth.identifierCode,
                onChange:identifierCodeHandler
              }}
            />
          )}
       </div>
      </div>
      <OptBtn  
        type="submit"
        text="تایید"
        isloading={isLoading}
        onClick={login}
    
      />


    </form>
  );
};

export default Step1;
