import { Fragment } from 'react';
import Form from '../form/Form';
import classes from './AddCard.module.scss';
import { useSelector,useDispatch } from 'react-redux';
import { UiActions } from 'store/slices/ui';

const AddCard=(props)=>{

   const dispatch=useDispatch();
   const uiState=useSelector(state=>state.ui);
   
   const changeShowHandler=()=>{
      dispatch(UiActions.addCardShowChange());
   }

    return (
            <Fragment>
               <div className={`${classes.addCard} ${uiState.addCard ? classes.activeAddCard : ''}`}>
                  <h4>{uiState.addCardState === 'updateCard'? 'ویرایش اطلاعات کارت':'ثبت کارت جدید'}</h4>
                  <Form/>
               </div>
               <div className={`${classes.shadow} ${uiState.addCard ? classes.activeShadow : ''}`} onClick={changeShowHandler}></div>
            </Fragment>

            )
}

export default AddCard;