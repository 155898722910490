import classes from './Dashboard.module.scss';
import Header from 'components/core/dashboardHeader/DashboardHeader';
import CartList from './components/cartList/CartList';

const Dashboard=()=>{
    return (
        <div className={classes.main}>
            <Header/>
            <CartList/>
        </div>
    );
}

export default Dashboard;