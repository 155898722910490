import { createSlice } from '@reduxjs/toolkit'

const initState = {
  title: '',
  image: '',
  amount: '',
  type: '',

  paymentFormData: {
    expireDate: '',
    pan: '',
    pin: '',
    cvv2: '',
  },

  paymentdata: {},

  cardTransferData: {
    availableBalance: 0,
    currency: 0,
    ledgerBalance: 0,
    rrn: '',
  },
}

const payment = createSlice({
  name: 'paymentData',
  initialState: initState,
  reducers: {
    setPaymentData(state, action) {
      state.title = action.payload.title
      state.amount = action.payload.amount
      state.image = action.payload.image
      state.type = action.payload.type
    },

    setPaymentFormData(state, action) {
      state.paymentFormData[action.payload.name] = action.payload.value
    },
    resetPaymentFormData(state, action) {
      state.paymentFormData = {
        expireDate: '',
        pin: '',
        cvv2: '',
      }
    },

    setPaymentTypeData(state, action) {
      state.PaymentTypeData.amount = action.payload.amount
      state.PaymentTypeData.mobile = action.payload.mobile
    },

    setCardTransferPayment(state, action) {
      state.cardTransferData.availableBalance = action.payload.availableBalance
      state.cardTransferData.currency = action.payload.currency
      state.cardTransferData.ledgerBalance = action.payload.ledgerBalance
      state.cardTransferData.rrn = action.payload.rrn
    },
    setPaymentResponse(state, action) {
      state.paymentdata = { ...action.payload }
    },
  },
})

export const PaymentAction = payment.actions

export default payment
