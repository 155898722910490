import { Fragment, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector, useDispatch } from 'react-redux'

import OptBtn from 'components/core/buttons/optBtn/OptBtn'
import DanjerBtn from 'components/core/buttons/danjerBtn/DanjerBtn'
import classes from './Message.module.scss'
import { modalActions } from 'store/slices/modal'

const Backdrop = (props) => {
  const show = useSelector((state) => state.modal.overlay)
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(modalActions.message(''))
  }
  return (
    <div
      className={`${classes.backdrop} ${show ? classes.activeOverlay : ''}`}
      onClick={closeModal}
    />
  )
}

const ModalOverlay = (props) => {
  const [iconState, setIconState] = useState()
  const [btnState, setBtnState] = useState()

  const message = useSelector((state) => state.modal.message)
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(modalActions.message(''))
  }

  useEffect(() => {
    if (message.type === 'success' || message.type === '') {
      setIconState(<span className={classes.check}>✓</span>)
      setBtnState(<OptBtn text="تایید" onClick={closeModal} />)
    } else if (message.type === 'error') {
      setIconState(<span className={classes.multiple}>×</span>)
      setBtnState(<DanjerBtn text="تایید" onClick={closeModal} />)
    }
  }, [message])

  return (
    <div
      className={`${classes.modalOverlay} ${
        message.text ? classes.modalOverlayActive : ''
      }`}
    >
      <header>
        {iconState}
        <span className={classes.text}>
          {message.type === 'success' || message.type === ''
            ? 'عملیات موفقیت آمیز'
            : 'عملیات ناموفق'}
        </span>
      </header>
      <main>
        <span>{message.text}</span>
        {btnState}
      </main>
      {props.title}
    </div>
  )
}

const Message = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById('backdrop-root'),
      )}
      {ReactDOM.createPortal(
        <ModalOverlay />,
        document.getElementById('modal-root'),
      )}
    </Fragment>
  )
}

export default Message
