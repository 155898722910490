import  classes from './DashboardHeader.module.scss';
import Logo from './logo/Logo';
import AsideMenu from '../asideMenu/AsideMenu';
import Help from './help/Help';

const DashboardHeader=()=>{
    return (
        <header className={classes.main}>
            <AsideMenu/>
            <Logo/>   
            <Help/>         
        </header>
    )
}

export default DashboardHeader;