import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import Header from 'components/core/pageHeader/Header';
import MyCards from 'components/core/myCards/MyCards';
import Form from './form/Form';
import { fetchCards } from 'store/actions/card';

const Balance = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCards('source'));
  }, []);

  return (
    <div className="page-gray">
      <Header title="دریافت موجودی" />
      <MyCards />
      <Form />
    </div>
  )
}

export default Balance;
