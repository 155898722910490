import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import FormContainer from 'components/core/formContainer/FormContainer'
import classes from './chargeReceiptData.module.scss'
import Receipt from 'components/core/receipt/Receipt'

const ChargeReceiptData = () => {
  const mobileNumber = useSelector((state) => state.form.charge.mobileNumber)
  const paymentForm = useSelector(
    (state) => state.payment.paymentFormData.pan,
  ).replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4')
  const payment = useSelector((state) => state.payment.paymentdata)

  return (
    <div className={classes.boxshadow}>
      <div className={classes.header}>
        <span> شماره موبایل:</span>
        <span>{mobileNumber}</span>
      </div>

      <div className={classes.header}>
        <span> از کارت:</span>
        <span>{paymentForm}</span>
      </div>

      <div className={classes.header}>
        <span> نوع شارژ:</span>
        <span>{`مستقیم`}</span>
      </div>

      <div className={classes.header}>
        <span>شماره ارجاع:</span>
        <span>{`${payment.refrence_number}`}</span>
      </div>
    </div>
  )
}
export default ChargeReceiptData
