import classes from './DanjerBtn.module.scss';

const DanjerBtn=(props)=>{
    return (
        <button className={classes.danjerBtn} onClick={props.onClick}>
            {props.text}
        </button>
    )
}

export default DanjerBtn;