import classes from './Logo.module.scss'

const Logo = () => {
  return (
    <div className={classes.navLogo}>
      <img
        className={classes.logo}
        src={`${process.env.PUBLIC_URL}/images/primaryLogo.png`}
        alt="navLogo"
      />
    </div>
  )
}

export default Logo
