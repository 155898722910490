import React from 'react'
import { Fragment } from 'react'
import Form from './form/Form'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { formActions } from 'store/slices/form'
import Header from 'components/core/pageHeader/Header'
import Inquiry from './inquery/inquery'
import { ChargeAmountAction } from 'store/slices/charge'

const BuyCharge = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(formActions.setMobileNumber(''))
    dispatch(ChargeAmountAction.setSelectedValue('10,000'))
  }, [])

  return (
    <Fragment>
      <div className="page-gray">
        <Header title="خرید شارژ"></Header>
        <Form />

        <Inquiry />
      </div>
    </Fragment>
  )
}
export default BuyCharge
