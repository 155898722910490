import { useSelector, useDispatch } from 'react-redux'

import classes from './Box.module.scss'
import Items from './items/Items'
import Logo from './logo/Logo'
import { UiActions } from 'store/slices/ui'

const Box = () => {
  const dispatch = useDispatch()
  const navShow = useSelector((state) => state.ui.navShow)

  const boxClasses = `${classes.box} ${navShow ? classes.openBox : ''}`
  const shadowClasses = `${classes.shadow} ${navShow ? classes.openShadow : ''}`

  const openStateHandler = () => {
    dispatch(UiActions.navShowChange())
  }

  return (
    <div className={classes.main}>
      <div className={boxClasses}>
        <Logo />
        <Items />
      </div>
      <div className={shadowClasses} onClick={() => openStateHandler()}></div>
    </div>
  )
}

export default Box
