import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import Header from 'components/core/pageHeader/Header'
import Tab from 'components/core/tab/Tab'
import MyCardList from './MyCardList/MyCardList'
import { UiActions } from 'store/slices/ui'

const ManageCard = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(UiActions.setManageCardTabSelected('source'))
  }, [])

  const setTab = (type) => {
    dispatch(UiActions.setManageCardTabSelected(type))
  }

  return (
    <div className="page-gray">
      <Header title="مدیریت کارت ها" />
      <Tab
        headers={[
          <span onClick={() => setTab('source')}>کارت های مبدا</span>,
          <span onClick={() => setTab('destination')}>کارت های مقصد</span>,
        ]}
        tab={[<MyCardList />, <MyCardList />]}
      />
    </div>
  )
}

export default ManageCard
