import { useState } from 'react'
import { modalActions } from 'store/slices/modal'
import { useDispatch, useSelector } from 'react-redux'
import { AuthActions } from 'store/slices/auth'

const useHttp = (requestConfig, success, error = null) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)

  const sendRequest = async () => {
    setIsLoading(true)

    let headerList = { 'Content-Type': 'application/json' }
    if (requestConfig.headers) {
      headerList = { ...headerList, ...requestConfig.headers }
    }
    if (!requestConfig.auth) {
      headerList = { ...headerList, token: token }
    }

    const response = await fetch(requestConfig.url, {
      method: requestConfig.method ? requestConfig.method : 'GET',
      headers: headerList,
      body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
    })

    try {
      const data = await response.json()
      if (response.ok) {
        if (requestConfig.auth) {
          const token = await response.headers.get('authorization')
          dispatch(AuthActions.setToken(token))
        }
        success(data)
      } else {
        if (error) {
          error(data)
        } else {
          dispatch(
            modalActions.message({
              type: 'error',
              text: data.errorMessage || 'خطا در عملیات',
            }),
          )
        }
      }
    } catch (error) {
      // dispatch(modalActions.message({
      //     type:"error",
      //     text: "خطا در عملیات"
      // }));
    }

    setIsLoading(false)
  }

  return {
    isLoading,
    sendRequest,
  }
}

export default useHttp
