import classes from './PaymentData.module.scss'
import { useSelector } from 'react-redux'

const PaymentData = (props) => {
  const PaymentData = useSelector((state) => state.payment)

  return (
    <div className={classes.main}>
      <div className={classes.list}>
        <div className={classes.header}>
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/images/${PaymentData.image}`}
            alt=""
          />
          <span className={classes.title}>{PaymentData.title}</span>
        </div>

        <span className={classes.amount}>{PaymentData.amount}</span>
      </div>
    </div>
  )
}
export default PaymentData
