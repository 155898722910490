import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import classes from './inquery.module.scss'
import { Fragment } from 'react'

import OptBtn from 'components/core/buttons/optBtn/OptBtn'
import { UiActions } from 'store/slices/ui'
import InquiryData from './inqueryData/inqueryData'

const Inquery = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const uiState = useSelector((state) => state.ui)

  const changeShowHandler = () => {
    dispatch(UiActions.inquiryShowChange())
  }

  const getPaymentCharge = () => {
    history.push('/chargePayment')
    dispatch(UiActions.inquiryShowChange())
  }

  return (
    <Fragment>
      <div
        className={`${classes.inquiry} ${
          uiState.inquiry ? classes.activeInquiry : ''
        }`}
      >
        <h4>تایید خرید شارژ</h4>
        <span className={classes.closeIcon} onClick={changeShowHandler}>
          &#10006;
        </span>

        <div className={classes.InqueryListData}>
          <InquiryData />
          <OptBtn type="submit" text="تایید" onClick={getPaymentCharge} />
        </div>
      </div>

      <div
        className={`${classes.shadow} ${
          uiState.inquiry ? classes.activeShadow : ''
        }`}
        onClick={changeShowHandler}
      ></div>
    </Fragment>
  )
}

export default Inquery
