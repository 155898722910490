import { createSlice } from '@reduxjs/toolkit'

const initState = {
  selectedAmount: '10,000',
  type: '',
}

const ChargeAmounts = createSlice({
  name: 'charge',
  initialState: initState,
  reducers: {
    setSelectedValue(state, action) {
      state.selectedAmount = action.payload
    },

    setOperatorType(state, action) {
      state.type = action.payload
    },
  },
})

export const ChargeAmountAction = ChargeAmounts.actions
export default ChargeAmounts
