import { useDispatch } from "react-redux";

import classes from "./Items.module.scss";
import { AuthActions } from "store/slices/auth";
import NavItem from "../navItem/NavItem";
import { UiActions } from "store/slices/ui";

const Items = () => {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(AuthActions.logout());
    dispatch(UiActions.closeNav());
    dispatch(AuthActions.setMobileNumber(''));
    dispatch(AuthActions.setActiveCode(''));
  };

  return (
    <ul className={classes.items}>
      <NavItem
        link="/dashboard"
        classname="icon-transaction"
        text="لیست تراکنش ها"
      />

      <NavItem link="/manageCard" classname="icon-card" text="مدیریت کارت ها" />

      <NavItem link="/dashboard" classname="icon-inbox" text="صندوق پیام ها" />

      <NavItem link="/dashboard" classname="icon-settings" text="تنظیمات" />

      <NavItem link="/dashboard" classname="icon-help" text="راهنما" />

      <NavItem link="/dashboard" classname="icon-aboutus" text="درباره ما" />

      <NavItem
        onClick={logOut}
        link="/dashboard"
        classname="icon-rightarrow"
        text="خروج"
      />
    </ul>
  );
};

export default Items;
