import { Link } from 'react-router-dom'

import classes from './Cart.module.scss'

const Cart = (props) => {
  return (
    <div className={classes.cart}>
      <Link to={props.to}>
        <img
          src={`${process.env.PUBLIC_URL}/images/svg/${props.image}`}
          alt="cart"
        />
        <h6>{props.text}</h6>
      </Link>
    </div>
  )
}
export default Cart
