import { Route, Switch, Redirect, HashRouter } from 'react-router-dom'
import { Fragment } from 'react'

import './App.scss'
import Login from 'screens/Login/Login'
import Dashboard from 'screens/dashboard/Dashboard'
import Balance from './screens/dashboard/pages/balance/Balance'
import CardToCard from 'screens/dashboard/pages/cardToCard/CardToCard'
import Bill from 'screens/dashboard/pages/bill/Bill'
import BillPayment from 'screens/dashboard/pages/bill/billPayment/BillPayment'
import BuyCharge from './screens/dashboard/pages/buyCharge/BuyCharge'
import ChargePayment from 'screens/dashboard/pages/buyCharge/chargePayment/ChargePayment'
import ManageCard from 'screens/asideMenu/manageCard/ManageCard'
import Message from 'components/modals/message/Message'
import useAuth from 'hooks/useAuth'
import Receipt from 'components/core/receipt/Receipt'
import Conection from 'components/core/conection/Conection'
import ChargeReceipt from 'screens/dashboard/pages/buyCharge/receipt/chargeReceipt'
import CardTransferReceipt from 'screens/dashboard/pages/cardToCard/receipt/cardTransferReceipt'

import BillReceipt from './screens/dashboard/pages/bill/billReceipt/BillReceipt'

function App() {
  const { isAuth } = useAuth()

  return (
    <Fragment>
      <Message />
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/login">
          {/* {!isAuth ? <Login /> : <Redirect to="/dashboard" />} */}
          <Login /> 
        </Route>
        <Route path="/dashboard">
        <Dashboard />
          {/* {isAuth ? <Dashboard /> : <Redirect to="/login" />} */}
        </Route>
        <Route path="/balance">
        <Balance />
          {/* {isAuth ? <Balance /> : <Redirect to="/login" />} */}
        </Route>
        <Route path="/cardToCard">
        <CardToCard />
          {/* {isAuth ? <CardToCard /> : <Redirect to="/login" />} */}
        </Route>
        <Route path="/bill">
          {isAuth ? <Bill /> : <Redirect to="/login" />}
        </Route>
        <Route path="/manageCard">
          {isAuth ? <ManageCard /> : <Redirect to="/login" />}
        </Route>
        <Route path="/billPayment">
          {isAuth ? <BillPayment /> : <Redirect to="/login" />}
        </Route>
        <Route path="/buyCharge">
          {isAuth ? <BuyCharge /> : <Redirect to="/login" />}
        </Route>
        <Route path="/chargePayment">
          {isAuth ? <ChargePayment /> : <Redirect to="/login" />}
        </Route>
        <Route path="/cardToCardReceipt">
          {isAuth ? <CardTransferReceipt /> : <Redirect to="/login" />}
        </Route>
        <Route path="/chargeReceipt">
          {isAuth ? <ChargeReceipt /> : <Redirect to="/login" />}
        </Route>  
          <Route path="/billReceipt">
          {isAuth ? <BillReceipt /> : <Redirect to="/login" />}
        </Route>
      </Switch>
    </Fragment>
  )
}

export default App
