import { useDispatch } from 'react-redux';

import classes from './Toggle.module.scss';
import { UiActions } from 'store/slices/ui';

const Toggle=()=>{

    const dispatch = useDispatch();
    const openNav=()=>{
        dispatch(UiActions.navShowChange());
    }

    return (
        <div className={classes.toggle} onClick={openNav}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default Toggle;