import { Fragment } from 'react';
import Form from './form/Form';

import Logo from './logo/Logo';

const Login=()=>{
    return (
        <Fragment>
            <Logo/>
            <Form/>
        </Fragment>
    )
}

export default Login;