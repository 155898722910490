import { createSlice } from '@reduxjs/toolkit'

const initState = {
  myCards: {
    title: '',
    cardNumber: '',
    expireDate: '',
  },
  balance: {
    expireDate: '',
    pan: '',
    pin: '',
    cvv2: '',
  },

  cardToCard: {
    destinationPan: '',
    pan: '',
    amount: '',
    expireDate: '',
    pin: '',
    cvv2: '',
  },

  charge: {
    mobileNumber: '',
  },
  bill: {
    billId: '',
    payId: '',
  },
}

const form = createSlice({
  name: 'form',
  initialState: initState,
  reducers: {
    setMyCardsValue(state, action) {
      state.myCards[action.payload.name] = action.payload.value
    },
    setMyCardsTotal(state, action) {
      state.myCards = action.payload
    },
    resetMyCards(state, action) {
      state.myCards = {
        title: '',
        cardNumber: '',
        expireDate: '',
      }
    },
    setBalanceValue(state, action) {
      state.balance[action.payload.name] = action.payload.value
    },
    resetBalanceValue(state, action) {
      state.balance = {
        expireDate: '',
        pan: '',
        pin: '',
        cvv2: '',
      }
    },

    setBalanceTotal(state, action) {
      state.balance = action.payload
    },
    setBillValue(state, action) {
      state.bill[action.payload.name] = action.payload.value
    },
    resetBillValue(state, action) {
      state.bill = {
        billId: '',
        payId: '',
      }
    },

    resetBalance(state, action) {
      state.myCards = {
        expireDate: '',
        pan: '',
        pin: '',
        cvv2: '',
      }
    },

    setMobileNumber(state, action) {
      state.charge.mobileNumber = action.payload
    },

    setCardToCardValue(state, action) {
      state.cardToCard[action.payload.name] = action.payload.value
    },
    resetCardToCardValue(state, action) {
      state.cardToCard = {
        destinationPan: '',
        amount: '',
        expireDate: '',
        pin: '',
        cvv2: '',
      }
    },
  },
})

export const formActions = form.actions

export default form
