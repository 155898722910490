import classes from './CartList.module.scss'
import Cart from 'components/core/cart/Cart'

const CartList = () => {
  return (
    <div className={classes.cartList}>
      <Cart text="موجودی" image="money.svg" to="/balance" />
      <Cart text="کارت به کارت" image="carttocart.svg" to="/cardToCard" />
      <Cart text="پرداخت قبوض" image="bill.svg" to="/bill" />
      <Cart text="خرید شارژ" image="phonecharge.svg" to="/buyCharge" />
      <Cart text="بسته اینترنت" image="internet.svg" to="/dashboard" />
      <Cart text="خلافی خودرو" image="car.svg" to="/dashboard" />
      <Cart text="خیریه" image="charity.svg" to="/dashboard" />
      <Cart text="بیمه" image="insurance.svg" to="/dashboard" />
      <Cart text="بلیط سینما" image="cinema.svg" to="/dashboard" />
    </div>
  )
}

export default CartList
