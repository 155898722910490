import { useSelector, useDispatch } from 'react-redux'
import { Fragment, useState } from 'react'

import classes from './MyCards.module.scss'
import { CardActions } from 'store/slices/card'

const MyCards = () => {
  const [startPosition, setStartPosition] = useState(0)
  const [movePosition, setMovePosition] = useState(0)

  const cards = useSelector((state) => state.cards.data)
  //const cards2 = localStorage['cardList']
  const dispatch = useDispatch()

  const selectedHandlerLeft = (itemIndex) => {
    dispatch(CardActions.goToLeft(itemIndex))
  }

  const selectedHandlerRight = (itemIndex) => {
    dispatch(CardActions.goToRight(itemIndex))
  }

  const handleTouchMove = (event, index) => {
    let move = event?.touches[0]?.clientX
    if (startPosition < move) {
      setMovePosition(move - startPosition)
      dispatch(CardActions.moveToRight({ index: index, x: movePosition }))
      //++
    } else if (startPosition > move) {
      setMovePosition(startPosition - move)
      dispatch(CardActions.moveToLeft({ index: index, x: movePosition }))
    }
  }

  const startTouchHadler = (event) => {
    setStartPosition(event?.touches[0]?.clientX)
  }

  const handleTouchEnd = (event, index) => {
    let move = event?.changedTouches[0]?.clientX
    if (startPosition < move) {
      dispatch(CardActions.goToLeft(index))
    } else if (startPosition > move) {
      dispatch(CardActions.goToRight(index))
    }
  }

  const cardsView = cards.map((item, index) => {
    return (
      <div
        className={classes.item}
        key={item.id}
        style={{ right: item.right }}
        onTouchStart={startTouchHadler}
        onTouchMove={(event) => handleTouchMove(event, index)}
        onTouchEnd={(event) => handleTouchEnd(event, index)}
      >
        {index + 1 !== cards.length && (
          <i
            className={`icon-left ${classes.left}`}
            onClick={() => selectedHandlerLeft(index)}
          ></i>
        )}
        {index !== 0 && (
          <i
            className={`icon-right ${classes.right}`}
            onClick={() => selectedHandlerRight(index)}
          ></i>
        )}
        <img
          src={`${process.env.PUBLIC_URL}/images/cards/${item.bank}`}
          alt="cardimage"
        />
        <span className={classes.number}>{item.cardNumber}</span>
        <span className={classes.name}>{item.title}</span>
        {item.expireDate && (
          <span className={classes.expire}>تاریخ انقضا: {item.expireDate}</span>
        )}
      </div>
    )
  })

  return (
    <Fragment>
      {cards.length > 0 && <div className={classes.myCards}>{cardsView}</div>}
    </Fragment>
  )
}

export default MyCards
