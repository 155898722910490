import classes from './Input.module.scss'
import InputMask from 'react-input-mask'

const Input = (props) => {
  window.addEventListener('resize', function () {
    if (document.activeElement.tagName == 'input') {
      window.setTimeout(function () {
        document.activeElement.scrollIntoViewIfNeeded()
      }, 0)
    }
  })
  return (
    <div className={`${classes.input} ${props.className}`}>
      <label>{props.label}</label>
      {props.mask ? (
        <InputMask mask={props.mask} {...props.input} />
      ) : (
        <input {...props.input} />
      )}
    </div>
  )
}

export default Input
