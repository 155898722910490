import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OptBtn from 'components/core/buttons/optBtn/OptBtn'
import FormContainer from 'components/core/formContainer/FormContainer'
import Input from 'components/core/input/Input'
import ChargeAmounts from '../chargeAmount/ChargeAmounts'
import classes from './Form.module.scss'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

import { ChargeAmountAction } from 'store/slices/charge'
import { formActions } from 'store/slices/form'
import { PaymentAction } from 'store/slices/payment'
import { UiActions } from 'store/slices/ui'
import { modalActions } from 'store/slices/modal'

const Form = () => {
  const dispatch = useDispatch()
  const amount = useSelector((state) => state.charge.selectedAmount)
  const mobileNumber = useSelector((state) => state.form.charge.mobileNumber)
  const oprType = useSelector((state) => state.charge.type)

  const setMobileNum = (value) => {
    dispatch(formActions.setMobileNumber(value))
    getOprType()
  }

  const getOprType = () => {
    const oprNum = mobileNumber.substring(0, 3)
    if (oprNum === '091' || oprNum === '099') {
      dispatch(ChargeAmountAction.setOperatorType('hamrahAval.png'))
    } else if (oprNum === '090' || oprNum === '093') {
      dispatch(ChargeAmountAction.setOperatorType('irancell.png'))
    } else if (oprNum === '092') {
      dispatch(ChargeAmountAction.setOperatorType('rightel.png'))
    }
  }

  const getDispatchData = () => {
    dispatch(
      PaymentAction.setPaymentData({
        title: `شارژ مستقیم /${mobileNumber}`,
        image: oprType,
        amount: `شارژ ${amount} ریالی`,
      }),
    )
  }

  const changeInqueryHandler = () => {
    if (mobileNumber && amount) {
      dispatch(UiActions.inquiryShowChange())
      getDispatchData()
    } else {
      dispatch(
        modalActions.message({
          type: 'error',
          text: 'شماره همراه و مبلغ را وارد کنید',
        }),
      )
    }
  }

  return (
    <div className="page-gray">
      <FormContainer>
        <form>
          <Input
            className={classes.mobileNumber}
            label="شماره همراه:"
            icon="icon-mobile"
            input={{
              type: 'number',
              placeholder: '- - - - - - - - - 9 0',
              icon: 'icon-mobile',
              onChange: (event) => setMobileNum(event.target.value),
            }}
          />
        </form>
        <ChargeAmounts />
        <OptBtn text="ادامه" type="submit" onClick={changeInqueryHandler} />
      </FormContainer>
    </div>
  )
}

export default Form
