import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import classes from './Payment.module.scss'
import FormContainer from 'components/core/formContainer/FormContainer'
import Input from 'components/core/input/Input'
import DynamicPassBtn from 'components/core/DynamicPassBtn/DynamicPassBtn'
import OpenBtn from 'components/core/buttons/optBtn/OptBtn'
import useHttp from 'hooks/useHttp'
import { formActions } from 'store/slices/form'
import { modalActions } from 'store/slices/modal'
import PaymentData from './paymentData/PaymentData'
import { ChargeAmountAction } from 'store/slices/charge'
import { PaymentAction } from 'store/slices/payment'
import { fetchCards } from 'store/actions/card'
import { useHistory } from 'react-router'

const Payment = (props) => {
  const dispatch = useDispatch()
  const paymentFormData = useSelector((state) => state.payment.paymentFormData)
  const selectedCard = useSelector((state) => state.cards.selected)
  const title = useSelector((state) => state.payment.title)
  const data = props.data
  const history = useHistory()

  useEffect(() => {
    dispatch(PaymentAction.resetPaymentFormData())
    setFormValue('pan', selectedCard.cardNumber)
  }, [selectedCard])

  const httpResponse = (data) => {
    dispatch(PaymentAction.setPaymentResponse(data))
    history.push(`/${props.receiptUrl}`)
  }

  const { isLoading, sendRequest } = useHttp(
    {
      url: `service/pelato/${props.url}`,
      method: 'POST',
      body: {
        ...paymentFormData,
        ...data,
      },
    },
    httpResponse,
  )
  const setFormValue = (name, value) => {
    let content=value.replace(/\s/g, '');

    dispatch(PaymentAction.setPaymentFormData({ name: name, value: content }))
  }

  const submitFormHandler = (event) => {
    event.preventDefault()
    sendRequest()
  }

  return (
    <div className="page-gray mt-5">
      <FormContainer className={classes.formContainer}>
        <PaymentData />
        <fieldset className={classes.fieldset} disabled={isLoading}>
          <form onSubmit={submitFormHandler} disable={paymentFormData}>
            <Input
              label="شماره کارت :"
              mask="9999 9999 9999 9999"
              input={{
                type: 'tel',
                value: paymentFormData.pan,
                onChange: (event) => setFormValue('pan', event.target.value),
              }}
            />
            <div className="row">
              <Input
                className="col-6 pl-10"
                label="cvv2:"
                input={{
                  type: 'password',
                  value: paymentFormData.cvv2,
                  onChange: (event) => setFormValue('cvv2', event.target.value),
                }}
              />
              <Input
                className="col-6"
                label=" تاریخ انقضا:"
                mask="99/99"
                input={{
                  type: 'tel',
                  value: paymentFormData.expireDate,
                  onChange: (event) =>
                    setFormValue('expireDate', event.target.value),
                }}
              />
            </div>

            <div className="row">
              <Input
                className="col-6 pl-10"
                label="رمز دوم:"
                input={{
                  type: 'password',
                  value: paymentFormData.pin,
                  onChange: (event) => setFormValue('pin', event.target.value),
                }}
              />
              <DynamicPassBtn className="col-6" />
            </div>
            <OpenBtn
              type="submit"
              text="پرداخت"
              isloading={isLoading}
              disable={!paymentFormData}
            />
          </form>
        </fieldset>
      </FormContainer>
    </div>
  )
}
export default Payment
