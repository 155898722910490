import classes from './AsideMenu.module.scss';
import Toggle from './toggle/Toggle';
import Box from './box/Box';

const AsideMenu=()=>{
    return (
        <div className={classes.asideMenu}>
            <Toggle/>
            <Box/>
        </div>
    )
}

export default AsideMenu;