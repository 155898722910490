import { useSelector } from 'react-redux';

import classes from './BillReceiptData.module.scss';

const BillReceiptData=(props)=>{
    
    const paymentFormData = useSelector((state) => state.payment.paymentFormData);
    const payment = useSelector((state) => state.payment.paymentdata);

    return(
        <div className={classes.boxshadow}>
        <div className={classes.header}>
          <span> کارت:</span>
          <span>{ paymentFormData.pan.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/,'$1-$2-$3-$4')}</span>
        </div>
        {/* <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/images/${payment.type}.png`}
            alt=""
          /> */}
        <div className={classes.header}>
          <span>نوع قبض  :</span>
          <span>{ payment.billTitle}</span>
        </div> 
         <div className={classes.header}>
          <span>شناسه قبض :</span>
          <span>{ payment.billId}</span>
        </div>
        <div className={classes.header}>
          <span>شناسه پرداخت :</span>
          <span>{payment.payId}</span>
        </div>
        <div className={classes.header}>
          <span>شماره پیگیری:</span>
          <span> {payment.referralNumber}</span>
        </div>
      </div>)
}
export default BillReceiptData;