import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import classes from './inquery.module.scss'
import { Fragment } from 'react'

import OptBtn from 'components/core/buttons/optBtn/OptBtn'
import { UiActions } from 'store/slices/ui'
import { modalActions } from 'store/slices/modal'
import InquiryData from './inqueryData/inqueryData'
import useHttp from 'hooks/useHttp'
import { PaymentAction } from 'store/slices/payment'

const Inquery = (props) => {
  const dispatch = useDispatch()
  const uiState = useSelector((state) => state.ui)
  const cardToCardData = useSelector((state) => state.form.cardToCard)
  const history = useHistory()
  const changeShowHandler = () => {
    dispatch(UiActions.inquiryShowChange())
  }

  const httpResponse = (data) => {
    dispatch(UiActions.inquiryShowChange())
    dispatch(PaymentAction.setCardTransferPayment(data))
    history.push('/cardToCardReceipt')
  }
  const { isLoading, sendRequest } = useHttp(
    {
      url: 'service/pelato/cardTransfer',
      method: 'POST',
      body: {
        ...cardToCardData,
      },
    },
    httpResponse,
  )

  const getPaymentCharge = () => {
    sendRequest()
  }

  return (
    <Fragment>
      <div
        className={`${classes.inquiry} ${
          uiState.inquiry ? classes.activeInquiry : ''
        }`}
      >
        <h4>استعلام کارت مقصد</h4>
        <span className={classes.closeIcon} onClick={changeShowHandler}>
          &#10006;
        </span>

        <div className={classes.InqueryListData}>
          <InquiryData />
          <OptBtn
            type="submit"
            text="تایید"
            onClick={getPaymentCharge}
            isloading={isLoading}
          />
        </div>
      </div>

      <div
        className={`${classes.shadow} ${
          uiState.inquiry ? classes.activeShadow : ''
        }`}
        onClick={changeShowHandler}
      ></div>
    </Fragment>
  )
}

export default Inquery
