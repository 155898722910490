import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classes from './MyCardList.module.scss'
import MyCards from 'components/core/myCards/MyCards'
import FormContainer from 'components/core/formContainer/FormContainer'
import Info from './info/Info'
import CrudBtn from './crudBtn/CrudBtn'
import AddCard from './addCard/AddCard'
import { fetchCards } from 'store/actions/card'
import EmptyCard from './emptyCard/EmptyCard'

const MyCardList = (props) => {
  const dispatch = useDispatch()
  const cards = useSelector((state) => state.cards)
  const uiState = useSelector((state) => state.ui)

  useEffect(() => {
    dispatch(fetchCards(uiState.manageCardTabSelected))
  }, [uiState.manageCardTabSelected])

  return (
    <div className={classes.myCardList}>
      {cards.data.length > 0 && <MyCards />}
      {cards.data.length > 0 && (
        <FormContainer>
          <Info title="عنوان کارت :" value={cards.selected.title} />
          <Info title="شماره کارت :" value={cards.selected.cardNumber} />
          {uiState.manageCardTabSelected === 'source' && (
            <Info title="تاریخ انقضا :" value={cards.selected.expireDate} />
          )}
        </FormContainer>
      )}
      {cards.data.length === 0 && <EmptyCard />}
      <CrudBtn />

      <AddCard />
    </div>
  )
}

export default MyCardList
