import { Fragment } from "react";
import { useSelector } from "react-redux";
import Receipt from "components/core/receipt/Receipt";
import OptBtn from "components/core/buttons/optBtn/OptBtn";
import FormContainer from "components/core/formContainer/FormContainer";
import { useHistory } from "react-router";
import BillreceiptData from "./billReceiptData/BillReceiptData";

const BillReceipt = (props) => {
  const history = useHistory();
  const amount = useSelector((state) => state.payment.paymentdata.amount)
  return (
    <Fragment>
      <FormContainer>
        <div>
          <Receipt amount={amount} title={` پرداخت قبض با موفقیت انجام شد`} />
          <BillreceiptData />
          <OptBtn
            type="submit"
            text="بازگشت"
            onClick={() => {
              history.push("/dashboard");
            }}
          />
        </div>
      </FormContainer>
    </Fragment>
  );
};
export default BillReceipt;
