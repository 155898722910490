
import { useHistory } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";

import useHttp from "hooks/useHttp";
import  classes from './Step2.module.scss';
import { AuthActions } from "store/slices/auth";
import Input from 'components/core/input/Input'
import OptBtn from 'components/core/buttons/optBtn/OptBtn'


const Step2=()=>{
    const history=useHistory();
    const dispatch=useDispatch();
    const auth=useSelector(state=>state.auth);

    
    const loginResponse=(data)=>{
        if(data.valid){
            history.push('/dashboard');
        }
    }

    const {isLoading,sendRequest}= useHttp(
        {
            url:"/service/mobchannel/customer/checkOtpCode",
            method:"POST",
            auth:true,
            body:{
                mobileNumber:auth.mobileNumber,
                otpCode:auth.activeCode,
                identifierCode:auth.identifierCode
            }
        },
        loginResponse
    )
    const login=(event)=>{
        event.preventDefault();
        sendRequest();
    }

    const codeStateHandler=(event)=>{
        dispatch(AuthActions.setActiveCode(event.target.value));
    }

    return (
            <form className={classes.form}>
                <h6>لطفا کد فعال سازی را وارد کنید : </h6>
                <div className={classes.formGroup}>
                <Input
                mask="99999"
                input={{
                    type:"tel",
                    value:auth.activeCode,
                    onChange:(event) =>codeStateHandler(event)
                }}
              />
                    {/* <input   max={4} type="number" value={auth.activeCode} onChange={codeStateHandler} /> */}
                </div>
                <OptBtn  
                    type="submit"
                    text="تایید"
                    isloading={isLoading}
                    onClick={login}
               />

            </form>
        )
}

export default Step2;
