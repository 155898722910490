import classes from './EmptyCard.module.scss';

const EmptyCard=(props)=>{
    return (
            <div className={classes.emptyCard}>
               <i className="icon-card"></i>
               <span>هنوز کارتی ثبت نشده است!</span>
            </div>
            )
}

export default EmptyCard;