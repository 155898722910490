import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import FormContainer from 'components/core/formContainer/FormContainer'
import Input from 'components/core/input/Input'
import DynamicPassBtn from 'components/core/DynamicPassBtn/DynamicPassBtn'
import OptBtn from 'components/core/buttons/optBtn/OptBtn'
import useHttp from 'hooks/useHttp'
import { formActions } from 'store/slices/form'
import { modalActions } from 'store/slices/modal'

const Form = () => {
  const dispatch = useDispatch()
  const balanceForm = useSelector((state) => state.form.balance)
  const selectedCard = useSelector((state) => state.cards.selected)

  useEffect(() => {
    dispatch(formActions.resetBalanceValue())
    setFormValue('pan', selectedCard.cardNumber)
  }, [selectedCard])

  const httpResponse = (data) => {
    let element = <span>موجودی : {data.available_balance}</span>
    dispatch(modalActions.message({ type: 'success', text: element }))
  }

  const { isLoading, sendRequest } = useHttp(
    {
      url: 'service/pelato/cardBalance',
      method: 'POST',
      body: {
        ...balanceForm,
      },
    },

    httpResponse,
  )

  const setFormValue = (name, value) => {

    let content=value.replace(/\s/g, '');
    dispatch(formActions.setBalanceValue({ name: name, value: content }));

    
    // dispatch(formActions.setBalanceValue({ name: name, value: value }))
  }

  const submitFormHandler = (event) => {
    event.preventDefault()
    sendRequest()
  }

  return (
    <div className="page-gray mt-5">
      <FormContainer>
        <form onSubmit={submitFormHandler}>
          <Input
            label="شماره کارت :"
            mask="9999 9999 9999 9999"
            input={{
              type: 'tel',
              value: balanceForm.pan,
              onChange: (event) => setFormValue('pan', event.target.value),
            }}
          />

          <div className="row">
            <Input
              className="col-6 pl-10"
              label="cvv2:"
              input={{
                type: 'password',
                value: balanceForm.cvv2,
                onChange: (event) => setFormValue('cvv2', event.target.value),
              }}
            />
            <Input
              className="col-6"
              label="تاریخ انقضا :"
              mask="99/99"
              input={{
                type: 'tel',
                value: balanceForm.expireDate,
                onChange: (event) =>
                  setFormValue('expireDate', event.target.value),
              }}
            />
          </div>

          <div className="row">
            <Input
              className="col-6 pl-10"
              label="رمز دوم :"
              input={{
                type: 'password',
                value: balanceForm.pin,
                onChange: (event) => setFormValue('pin', event.target.value),
              }}
            />

            <DynamicPassBtn className="col-6" />
          </div>
          <OptBtn type="submit" text="دریافت موجودی" />
        </form>
      </FormContainer>
    </div>
  )
}

export default Form
