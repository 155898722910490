import { useState } from 'react';
import classes from './Tab.module.scss';

const Tab=(props)=>{
    const [tabSelectedState,setTabSelectedState]=useState(0);

    const tabSelectHandler=(item,index)=>{
        setTabSelectedState(index);
    }

    return (
        <div className={classes.tab}>
            <ul>
                {props.headers.map((item,index)=>{
                    return <li className={tabSelectedState===index ? classes.active : ""} onClick={()=>tabSelectHandler(item,index)}>{item}</li>
                })}
            </ul>

            <main>
                {props.tab[tabSelectedState]}
            </main>
        </div>
    )
}

export default Tab;